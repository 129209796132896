import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import { SEO, SeoObject } from "../components/seo";
import { BlogPostCard } from "../components/blog-post-card";

export const Blog = ({ data }) => {
  let meta: SeoObject = {
    description: "Pearl Harbor Experience",
    title: `Blog - Pearl Harbor Experience`,
  };

  return (
    <Layout>
      <SEO meta={meta} />
      <div className="max-w-screen-lg grid gap-6 grid-cols-2 m-auto py-6">
        {data.posts.nodes.map(({ excerpt, frontmatter, fields, id }) => {
          return <BlogPostCard {...{ excerpt, frontmatter, fields }} key={id} />;
        })}
      </div>
    </Layout>
  );
};

export default Blog;

export const query = graphql`
  query BLOG_INDEX_QUERY {
    posts: allMdx(sort: { fields: [frontmatter___date], order: DESC }, filter: { frontmatter: { layout: { eq: "post" } } }, limit: 20) {
      nodes {
        id
        excerpt(pruneLength: 250)
        frontmatter {
          image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, aspectRatio: 1.5, formats: [AUTO, WEBP, AVIF])
            }
          }
          title
          slug
          date(formatString: "DD MMMM YYYY")
          relativeDate: date(fromNow: true)
          isoDate: date(formatString: "YYYY-MM-DDTHH:mm:ssZ")
        }
      }
    }
  }
`;
