import { Link } from "gatsby";
import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const BlogPostCard = ({ excerpt, frontmatter, fields }) => {
  const { tags = [], slug, title, date, image } = frontmatter;
  return (
    <Link to={`${slug}/`}>
      <div className="bg-white shadow-lg transform transition duration-500 hover:scale-105">
        <div className="blog-post-card-image">
          <GatsbyImage image={getImage(image)} alt={""} />
        </div>

        <div className="bg-white shadow-lg p-6 prose redbordertop">
          <div className="blog-post-card-title">
            <h3 className="m-0">{title}</h3>
          </div>

          <div className="blog-post-meta">
            <div className="blog-post-meta-datetime">
              <div className="blog-post-meta-date">{date}</div>
              <div className="blog-post-meta-reading-time">{fields?.readingTime?.text}</div>
            </div>
          </div>

          <div className="blog-post-card-excerpt bp3-text-muted ">
            <em>{frontmatter?.excerpt || excerpt}</em>
          </div>
        </div>
      </div>
    </Link>
  );
};
